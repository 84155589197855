import './index.scss'
import fullpage from 'fullpage.js'
import 'fullpage.js/dist/fullpage.min.css'
import Swiper from 'swiper/dist/js/swiper.min.js'



//首页全屏滚动
var indexfullpage;
function initFullpage() {
  indexfullpage = new fullpage('#fullpage', {
    scrollingSpeed: 600,
    verticalCentered:true,
    css3: true,
    resize:true,
    autoScrolling: true,
    scrollBar: false,
    anchors: ['page1', 'page2', 'page3', 'page4','page5'],
    afterLoad: function (origin, destination, direction, trigger) {
      // console.log('afterLoad', origin.index, destination.index, direction, trigger)
    },
    onLeave: function (origin, destination, direction,trigger) {
      // console.log('onLeave', origin.index, destination.index, direction,trigger)
      let index = destination.index
      if(index == 0 ){
        $('.header .left .nav').fadeIn(200)
        $('.header-aside .btn-icon').removeClass('scroll')
      }else{
        $('.header .left .nav').fadeOut(200)
        $('.header-aside .btn-icon').addClass('scroll')
      }
      if(index == 1 || index == 3){
        $('.header').addClass('black')
      }else{
        $('.header').removeClass('black')
      }
    },
  });
}

$(document).ready(function(){
  if($(window).width() > 1024){
    initFullpage()
    $('.header-aside .back-top').on('click',function(){
      indexfullpage.moveTo('page1',0)
    })
  }
  setTimeout(indexbannerInit(),100)
})

// banner图轮播
function indexbannerInit(){
  var indexswiper = new Swiper(".index-banner .swiper", {
    speed:800,
    loop:true,
    autoplay: {
      delay: 6000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      waitForTransition: false,
    },
    pagination: {
      el: '.index-banner .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<div class="' + className + '"><svg><circle></circle></svg></div>';
      },
    },
    on:{
      init:function(swiper){
          let slide=this.slides.eq(0);
          slide.addClass('ani-slide');
          setTimeout(function(){
            $('.index-banner .banner-t').addClass('active')
          },300)
      },
      transitionStart: function(){
          for(let i=0;i<this.slides.length;i++){
            let slide=this.slides.eq(i);
            slide.removeClass('ani-slide');
        }
      },
      transitionEnd: function(){
        let slide=this.slides.eq(this.activeIndex);
        slide.addClass('ani-slide');
      },
    }
  });
}


// 产品
var proswiper = new Swiper(".section2 .swiper-cont>.swiper", {
  effect : 'fade',
  speed:600,
  allowTouchMove: false,
});

var proimgswiper = new Swiper(".section2 .pro-swiper .left .swiper", {
  allowTouchMove: false,
  speed:600,
});
var protextswiper = new Swiper(".section2 .pro-swiper .right .swiper", {
  // allowTouchMove: false,
  watchSlidesProgress : true,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  navigation: {
    nextEl: ".pro-swiper .right .swiper-button-next",
    prevEl: ".pro-swiper .right .swiper-button-prev",
  },
  pagination: {
    el: ".pro-swiper .right .swiper-pagination",
    type: "progressbar",
  },
  on:{
    init:function(){
        let total = this.slides.length > 9 ? this.slides.length : '0'+this.slides.length
        $('.pro-swiper .right .swiper-page .total').html(total)
    },
    transitionStart: function(){
      let activeNum = this.realIndex+1
      activeNum = activeNum > 9 ? activeNum : '0'+ activeNum
      $('.pro-swiper .right .swiper-page .active-num').html(activeNum)
      proimgswiper.slideTo(this.realIndex)
    },
  }
});


var tradeimgswiper = new Swiper(".section2 .trade-swiper .left .swiper", {
  allowTouchMove: false,
  speed:600,
});
var tradetextswiper = new Swiper(".section2 .trade-swiper .right .swiper", {
  // allowTouchMove: false,
  watchSlidesProgress : true,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  navigation: {
    nextEl: ".trade-swiper .right .swiper-button-next",
    prevEl: ".trade-swiper .right .swiper-button-prev",
  },
  pagination: {
    el: ".trade-swiper .right .swiper-pagination",
    type: "progressbar",
  },
  on:{
    init:function(){
        let total = this.slides.length > 9 ? this.slides.length : '0'+this.slides.length
        $('.trade-swiper .right .swiper-page .total').html(total)
    },
    transitionStart: function(){
      let activeNum = this.realIndex+1
      activeNum = activeNum > 9 ? activeNum : '0'+ activeNum
      $('.trade-swiper .right .swiper-page .active-num').html(activeNum)
      tradeimgswiper.slideTo(this.realIndex)
    },
  }
});

$('.section2 .swiper-tab div').hover(function(){
  $(this).addClass('active').siblings().removeClass('active')
  let index = $(this).index()
  proswiper.slideTo(index)
})

//为什么选择我们
var whyswiper = new Swiper(".section3 .right .swiper", {
  // allowTouchMove: false,
  direction: 'vertical',
  slidesPerView : 5,
  centeredSlides: true,
  watchSlidesProgress : true,
  speed: 500,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: ".section3 .left .swiper-button-next",
    prevEl: ".section3 .left .swiper-button-prev",
  },
  pagination: {
    el: ".section3 .left .swiper-pagination",
    type: "progressbar",
    progressbarOpposite: true,
  },
  breakpoints: {
    767:{
      direction: 'horizontal',
      slidesPerView : 1.3,
      centeredSlides: false,
      pagination: {
        el: ".section3 .left .swiper-pagination",
        type: "progressbar",
        progressbarOpposite: false,
      },
    }
  },
  on:{
    init:function(){
        let total = this.slides.length > 9 ? this.slides.length : '0'+this.slides.length
        $('.section3 .left .swiper-page .total').html(total)
    },
    transitionStart: function(){
      let activeNum = this.realIndex+1
      activeNum = activeNum > 9 ? activeNum : '0'+ activeNum
      $('.section3 .left .swiper-page .active-num').html(activeNum)
      if($(window).width() > 767){
        $(this.slides).removeClass('active').removeClass('prev')
        $(this.slides[this.realIndex]).addClass('active')
        $(this.slides[this.realIndex + 1]).addClass('prev')
        $(this.slides[this.realIndex - 1]).addClass('prev')
      }
    },
  }
});


//新闻轮播
var newswiper = new Swiper(".section4 .right .swiper", {
  slidesPerView : 2,
  // slidesPerGroup : 2,
  spaceBetween : 30,
  watchSlidesProgress : true,
  speed: 500,
  navigation: {
    nextEl: ".section4 .left .swiper-button-next",
    prevEl: ".section4 .left .swiper-button-prev",
  },
  pagination: {
    el: ".section4 .right .swiper-pagination",
    type: "progressbar",
  },
  breakpoints: {
    768:{
      slidesPerView : 2,
      spaceBetween : 15,
    },
    640:{
      slidesPerView : 1,
      spaceBetween : 15,
    }
  },
  on:{
    init:function(){
        let total = this.slides.length > 9 ? this.slides.length : '0'+this.slides.length
        $('.section4 .right .swiper-page .total').html(total)
    },
    transitionStart: function(){
      let activeNum = this.realIndex+1
      activeNum = activeNum > 9 ? activeNum : '0'+ activeNum
      $('.section4 .right .swiper-page .active-num').html(activeNum)
    },
  }
});